import { render, staticRenderFns } from "./HomeHero.vue?vue&type=template&id=a9133d5a&scoped=true"
import script from "./HomeHero.js?vue&type=script&lang=js&external"
export * from "./HomeHero.js?vue&type=script&lang=js&external"
import style0 from "./HomeHero.scss?vue&type=style&index=0&id=a9133d5a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9133d5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default,HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default,HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})

export default {
  props: {
    titles: Object,
    tools: Array,
  },

  data() {
    return {
      tabActiveIndex: 0,
    }
  },

  components: {
    hotSupersonicTitlesWrapper: () => import('~/components/Molecules/Supersonic/TitlesWrapper/TitlesWrapper.vue'),
    hotSupersonicChecklist: () => import('~/components/Molecules/Supersonic/Checklist/Checklist.vue'),
    hotSupersonicTestimonialsCard: () =>
      import('~/components/Molecules/Supersonic/TestimonialsCard/TestimonialsCard.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  methods: {
    setTabActive(index) {
      this.tabActiveIndex = index
    },
  },
}

import { render, staticRenderFns } from "./EmbedSso.vue?vue&type=template&id=1387d1db&scoped=true"
import script from "./EmbedSso.js?vue&type=script&lang=js&external"
export * from "./EmbedSso.js?vue&type=script&lang=js&external"
import style0 from "./EmbedSso.scss?vue&type=style&index=0&id=1387d1db&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1387d1db",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotIframeSso: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/IframeSso/IframeSso.vue').default})

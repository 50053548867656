
export default {
  components: {
    hotNewHero: () => import('~/components/Organisms/NewHero/NewHero.vue'),
    hotHomeBannerCalculator: () => import('~/components/Organisms/HomeBannerCalculator/HomeBannerCalculator.vue'),
    hotHomeBannerImage: () => import('~/components/Organisms/HomeBannerImage/HomeBannerImage.vue'),
    hotHomeBannerPricing: () => import('~/components/Organisms/HomeBannerPricing/HomeBannerPricing.vue'),
    hotHomeAccordionToTab: () => import('~/components/Organisms/HomeAccordionToTab/HomeAccordionToTab.vue'),
    hotSocialProof: () => import('~/components/Organisms/SocialProof/SocialProof.vue'),
    hotHomeFaq: () => import('~/components/Organisms/HomeFaq/HomeFaq.vue'),
    hotHomeBannerFooter: () => import('~/components/Organisms/HomeBannerFooter/HomeBannerFooter.vue'),
    hotFixedCta: () => import('~/components/Organisms/FixedCta/FixedCta.vue'),
  },
}

import { render, staticRenderFns } from "./AccordionItem.vue?vue&type=template&id=175991b4&scoped=true"
import script from "./AccordionItem.js?vue&type=script&lang=js&external"
export * from "./AccordionItem.js?vue&type=script&lang=js&external"
import style0 from "./AccordionItem.scss?vue&type=style&index=0&id=175991b4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175991b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default})

import { render, staticRenderFns } from "./HomeCta.vue?vue&type=template&id=d76ec00c&scoped=true"
import script from "./HomeCta.js?vue&type=script&lang=js&external"
export * from "./HomeCta.js?vue&type=script&lang=js&external"
import style0 from "./HomeCta.scss?vue&type=style&index=0&id=d76ec00c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d76ec00c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default,HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})

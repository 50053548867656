export default {
  data() {
    return {
      isBot: false,
    }
  },

  methods: {
    checkBotUserAgent() {
      const userAgent = navigator.userAgent
      const botUserAgents = [
        'Googlebot',
        'Google-InspectionTool',
        'Storebot-Google',
        'Google-Extended',
        'Bingbot',
        'Slurp',
        'DuckDuckBot',
        'Baiduspider',
        'YandexBot',
        'Sogou',
        'Exabot',
        'facebot',
        'ia_archiver',
      ]

      this.isBot = botUserAgents.some(bot => userAgent.includes(bot))
    },
  },
}

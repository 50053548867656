export default {
  props: {
    titles: Object,
    items: Array,
  },

  components: {
    hotSupersonicTitlesWrapper: () => import('~/components/Molecules/Supersonic/TitlesWrapper/TitlesWrapper.vue'),
  },

  computed: {
    accordionClasses() {
      const routePath = this.$route.path
      const locale = this.$i18n.locale
      if (routePath === '/en' && locale === 'en') return 'type--en'

      return false
    },
  },
}

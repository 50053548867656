import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import SupersonicTestimonialsCard from '~/components/Molecules/Supersonic/TestimonialsCard/TestimonialsCard.vue'

export default {
  props: {
    titles: Object,
    testimonials: Array,
    differentials: Array,
  },

  components: {
    hotSupersonicTitlesWrapper: () => import('~/components/Molecules/Supersonic/TitlesWrapper/TitlesWrapper.vue'),
    hotSupersonicTestimonialsCard: () =>
      import('~/components/Molecules/Supersonic/TestimonialsCard/TestimonialsCard.vue'),
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  data() {
    return {
      animation: {},
      isMobile: null,
      SupersonicTestimonialsCard: SupersonicTestimonialsCard,
      swiperOptions: {
        slidesPerView: 1.25,
        spaceBetween: 16,
        allowTouchMove: true,
        slideToClickedSlide: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          420: {
            slidesPerView: 1.5,
          },
          580: {
            slidesPerView: 1.75,
          },
          720: {
            slidesPerView: 2.25,
          },
          860: {
            slidesPerView: 2.75,
          },
        },
      },
    }
  },

  methods: {
    initGSAP() {
      const section = this.$refs.socialProofSection
      const wrapper = this.$refs.socialProofWrapper
      const content = this.$refs.socialProofContent
      const wrapperWidth = this.getContentWidth(wrapper)
      const contentWidth = content.offsetWidth

      if (contentWidth <= wrapperWidth) return

      gsap.registerPlugin(ScrollTrigger)
      this.animation = gsap.to(content, {
        x: wrapperWidth - contentWidth,
        ease: 'none',
        scrollTrigger: {
          trigger: section,
          pin: true,
          scrub: true,
          toggleClass: 'active',
          start: 'center center',
          end: `+=${contentWidth * 0.5}`,
        },
      })
    },
    getContentWidth(element) {
      const styles = getComputedStyle(element)

      return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth > 1024) {
        this.isMobile = false
        setTimeout(this.initGSAP, 100)

        return
      }

      this.isMobile = true
    })
  },

  unmounted() {
    this.animation.kill()
  },
}

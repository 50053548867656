export default {
  data() {
    return {
      gtmSubsection: 'Corporate Website',
      gtmSubsection2: '',
      gtmSubsection3: '',
      expCookieValue: this.$cookies.get('exp') ? this.$cookies.get('exp') : '',

      dataLayerGA4: {
        event: 'pageview',
        section: 'Corporate Website',
        subsection: '',
        subsection2: '',
        subsection3: '',
        language: this.$i18n.locale,
      },

      datalayerCustomEventGA4: {
        event: 'custom_event',
        custom_event_name: '',
        event_details: '',
      },
    }
  },

  methods: {
    gtmPageviewGA4(subsection, subsection2, subsection3) {
      this.dataLayerGA4.subsection = subsection
      this.dataLayerGA4.subsection2 = subsection2
      this.dataLayerGA4.subsection3 = subsection3
      this.$gtm.push({ ...this.dataLayerGA4 })
    },

    gtmPushCustomEventGA4(name = '', details = '') {
      this.datalayerCustomEventGA4.custom_event_name = name
      this.datalayerCustomEventGA4.event_details = details
      this.$gtm.push({ ...this.datalayerCustomEventGA4 })
    },
  },

  mounted() {
    this.gtmPageviewGA4(this.gtmSubsection, this.gtmSubsection2, this.expCookieValue)

    this.$nextTick(() => {
      const handleWindowLoad = () => {
        const elementsWithEventAttributes = document.querySelectorAll('[event_name][event_details]')

        if (elementsWithEventAttributes.length) {
          elementsWithEventAttributes.forEach(element => {
            element.addEventListener(
              'click',
              () => {
                const eventName = element.getAttribute('event_name')
                const eventDetails = element.getAttribute('event_details')
                this.gtmPushCustomEventGA4(eventName, eventDetails)
              },
              { passive: true }
            )
          })
        }
      }

      window.addEventListener('load', handleWindowLoad)
    })
  },
}

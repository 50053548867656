import { render, staticRenderFns } from "./IframeSso.vue?vue&type=template&id=803c9b98&scoped=true"
import script from "./IframeSso.js?vue&type=script&lang=js&external"
export * from "./IframeSso.js?vue&type=script&lang=js&external"
import style0 from "./IframeSso.scss?vue&type=style&index=0&id=803c9b98&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "803c9b98",
  null
  
)

export default component.exports
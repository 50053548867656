export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    checklist: {
      type: Array,
    },
    icon: {
      type: Array,
      default: () => ['fa', 'check'],
    },
  },
}

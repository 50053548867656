export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    body: {
      type: String,
      required: true,
    },

    openByDefault: {
      type: Boolean,
      default: false,
    },

    dataAttributes: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      isOpen: this.openByDefault,
    }
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}

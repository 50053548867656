import modal from '~/mixins/modal.js'

export default {
  mixins: [modal],

  data() {
    return {
      currentStep: 0,
      result: false,
      min: 0,
      max: 0,
    }
  },

  props: {
    data: {
      type: [Array, Object],
      required: true,
    },

    locale: {
      type: String,
    },
  },

  components: {
    hotProgressBar: () => import('~/components/Molecules/ProgressBar/ProgressBar.vue'),
    hotSupersonicCalculatorForm: () => import('~/components/Molecules/Supersonic/CalculatorForm/CalculatorForm.vue'),
    hotSupersonicCalculatorResult: () =>
      import('~/components/Molecules/Supersonic/CalculatorResult/CalculatorResult.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  created() {
    this.currentStep = 0
  },

  computed: {
    getTotalSteps() {
      return this.data.steps.length
    },
  },

  methods: {
    actualStep(data) {
      this.currentStep = data
    },

    showResult(data) {
      this.result = data.result
      this.min = data.min
      this.max = data.max
    },

    close(e) {
      this.$modal.close(this.id)
    },
  },
}


import supersonicTest7HomeHero from '~/components/Supersonic/Test7/HomeHero/HomeHero.vue'
import supersonicHomeExperts from '~/components/Supersonic/HomeExperts/HomeExperts.vue'
import supersonicHomeHowToStart from '~/components/Supersonic/HomeHowToStart/HomeHowToStart.vue'
import supersonicHomeAdvantages from '~/components/Supersonic/HomeAdvantages/HomeAdvantages.vue'
import supersonicHomeEarningPotencial from '~/components/Supersonic/HomeEarningPotencial/HomeEarningPotencial.vue'
import supersonicHomeSocialProof from '~/components/Supersonic/HomeSocialProof/HomeSocialProof.vue'
import supersonicHomeTools from '~/components/Supersonic/HomeTools/HomeTools.vue'
import supersonicHomeCta from '~/components/Supersonic/HomeCta/HomeCta.vue'
import supersonicHomeFaq from '~/components/Supersonic/HomeFaq/HomeFaq.vue'
import hotAnchorList from '~/components/Molecules/AnchorList/AnchorList.vue'
export default {
  components: {
    supersonicTest7HomeHero,
    supersonicHomeExperts,
    supersonicHomeHowToStart,
    supersonicHomeTools,
    supersonicHomeAdvantages,
    supersonicHomeEarningPotencial,
    supersonicHomeSocialProof,
    supersonicHomeCta,
    supersonicHomeFaq,
    hotAnchorList,
  },
}

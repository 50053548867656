import { getDeviceSize } from '~/utils/device'
import debounce from 'lodash.debounce'
import hotSupersonicChecklist from '~/components/Molecules/Supersonic/Checklist/Checklist.vue'
import hotEmbedSso from '~/components/Molecules/EmbedSso/EmbedSso.vue'

export default {
  props: {
    title: String,
    titleB: {
      type: String,
      required: false,
      default: '',
    },
    checklist: Array,
    button: Object,
    images: Array,
    typewritter: Object,
    reviewSeal: Object,
  },

  components: {
    hotSupersonicChecklist,
    hotEmbedSso,
  },

  data() {
    return {
      timeArray: [50, 90],
      counter: 0,
      word: '',
      wordLength: 0,
      wordBox: null,
      letterArray: [],
      spellTimer: null,
      deleteTimer: null,
      animate: true,
      isDesktop: null,
    }
  },

  computed: {
    firstPhrase() {
      return this.typewritter.phrases[0]
    },

    isPT() {
      return ['pt-br'].includes(this.$i18n.locale)
    },
    hasReviewSeal() {
      if (this.reviewSeal) return true
    },
  },

  methods: {
    init(firstTime = false) {
      if (this.animate) {
        if (this.counter > this.typewritter.phrases.length - 1) this.counter = 0

        this.word = this.typewritter.phrases[this.counter]
        this.wordLength = this.word.length
        this.letterArray = this.word.split('')

        if (firstTime) this.delete()
        else this.spell(this.wordLength)
      }
    },

    spell(wordLength) {
      let spellSpeed = this.timeArray[Math.floor(Math.random() * this.timeArray.length)]

      this.spellTimer = setTimeout(() => {
        this.wordBox.innerHTML += this.letterArray[this.wordLength - wordLength]

        if (--wordLength) this.spell(wordLength)
        else
          setTimeout(() => {
            this.delete()
          }, 2000)
      }, spellSpeed)
    },

    delete() {
      if (!this.wordBox) {
        this.wordBox = this.$refs['hero__word-box']

        this.init(true)

        return
      }

      this.deleteTimer = setTimeout(() => {
        const string = this.wordBox.textContent ? this.wordBox.textContent : ''
        const newString = string.substring(0, string.length - 1)
        this.wordBox.textContent = newString

        if (--this.wordLength) this.delete()
        else
          setTimeout(() => {
            this.counter += 1
            this.init()
          }, 250)
      }, 50)
    },

    checkDeviceSize() {
      const device = getDeviceSize()
      const regex = /^(?=.*desktop-[xml])(?!.*desktop-[^xml]).*$/

      if (regex.test(device)) {
        this.isDesktop = true
        this.hasDeviceSize = true
      } else {
        this.isDesktop = false
        this.hasDeviceSize = true
      }
    },

    resize(e) {
      this.resizeDebounce(e, this)
    },

    scrollToTop() {
      if (this.isDesktop) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        const top = document.getElementById('iframe-sso')?.offsetTop

        window.scrollTo({ top, behavior: 'smooth' })
      }
    },

    resizeDebounce: debounce((e, self) => {
      const deviceSize = getDeviceSize()

      const regex = /^(?=.*desktop-[xml])(?!.*desktop-[^xml]).*$/

      self.isDesktop = regex.test(deviceSize)
    }, 100),
  },

  mounted() {
    this.$nextTick(() => {
      if (!this.isWithoutLettering) {
        this.wordBox = this.$refs['hero__word-box']
        this.init(true)
      }

      window.addEventListener('resize', this.resize)

      window.dispatchEvent(new Event('resize'))
      this.checkDeviceSize()
    })
  },

  beforeDestroy() {
    this.animate = false

    window.removeEventListener('resize', this.resize)
  },
}

import SupersonicExpertsCard from '~/components/Molecules/Supersonic/ExpertsCard/ExpertsCard.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

export default {
  props: {
    titles: Object,
    button: Object,
    experts: Array,
  },

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
    hotSupersonicTitlesWrapper: () => import('~/components/Molecules/Supersonic/TitlesWrapper/TitlesWrapper.vue'),
    hotSupersonicExpertsCard: () => import('~/components/Molecules/Supersonic/ExpertsCard/ExpertsCard.vue'),
  },

  data() {
    return {
      animation: {},
      isMobile: null,
      SupersonicExpertsCard: SupersonicExpertsCard,
      swiperOptions: {
        slidesPerView: 1.25,
        spaceBetween: 16,
        allowTouchMove: true,
        slideToClickedSlide: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          414: {
            slidesPerView: 1.75,
          },
          580: {
            slidesPerView: 2.25,
          },
          768: {
            slidesPerView: 3.25,
          },
          1024: {
            slidesPerView: 'auto',
          },
        },
      },
    }
  },

  methods: {
    initGSAP() {
      const section = this.$refs.expertsSection
      const wrapper = this.$refs.expertsWrapper
      const container = this.$refs.expertsContainer
      const content = this.$refs.expertsContent
      const wrapperWidth = this.getContentWidth(container)
      const contentWidth = content.offsetWidth

      if (contentWidth <= wrapperWidth) return

      gsap.registerPlugin(ScrollTrigger)
      this.animation = gsap.to(content, {
        x: wrapperWidth - contentWidth,
        ease: 'none',
        scrollTrigger: {
          trigger: section,
          pin: true,
          scrub: true,
          toggleClass: 'active',
          start: 'top top',
          end: `+=${contentWidth * 0.5}`,
        },
      })
    },

    getContentWidth(element) {
      const styles = getComputedStyle(element)

      return element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth > 1024) {
        this.isMobile = false
        this.initGSAP()

        return
      }

      this.isMobile = true
    })
  },

  unmounted() {
    this.animation.kill()
  },

  computed: {
    hasButton() {
      return Boolean(this.button)
    },

    expertContentClass() {
      return this.$i18n.locale === 'pt-br' ? 'pt' : 'default'
    },
  },
}

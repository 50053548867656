import { render, staticRenderFns } from "./HomeAdvantages.vue?vue&type=template&id=d02e00ce&scoped=true"
import script from "./HomeAdvantages.js?vue&type=script&lang=js&external"
export * from "./HomeAdvantages.js?vue&type=script&lang=js&external"
import style0 from "./HomeAdvantages.scss?vue&type=style&index=0&id=d02e00ce&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d02e00ce",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default,HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})

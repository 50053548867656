import { render, staticRenderFns } from "./HomeHowToStart.vue?vue&type=template&id=b6c766f0&scoped=true"
import script from "./HomeHowToStart.js?vue&type=script&lang=js&external"
export * from "./HomeHowToStart.js?vue&type=script&lang=js&external"
import style0 from "./HomeHowToStart.scss?vue&type=style&index=0&id=b6c766f0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c766f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotButton: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Button/Button.vue').default,HotImage: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Image/Image.vue').default})

import { render, staticRenderFns } from "./AnchorList.vue?vue&type=template&id=7f483ff0&scoped=true"
import script from "./AnchorList.js?vue&type=script&lang=js&external"
export * from "./AnchorList.js?vue&type=script&lang=js&external"
import style0 from "./AnchorList.scss?vue&type=style&index=0&id=7f483ff0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f483ff0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotLink: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Link/Link.vue').default})

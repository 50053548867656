import ComissionCalculator from '../ComissionCalculator/ComissionCalculator.vue'

export default {
  components: {
    ComissionCalculator,
    hotSupersonicTitlesWrapper: () => import('~/components/Molecules/Supersonic/TitlesWrapper/TitlesWrapper.vue'),
  },

  props: {
    titles: Object,
    items: Array,
    button: Object,
    image: Object,
    modal: Object,
    buttonModal: Object,
    reversed: Boolean,
  },

  data() {
    return {
      isMobile: null,
    }
  },

  computed: {
    hasButtonModal() {
      return Boolean(this.buttonModal)
    },
    hasButton() {
      return Boolean(this.button)
    },
  },

  methods: {
    openModal(e, data) {
      const modalOptions = {
        component: ComissionCalculator,
        data: this.modal,
        locale: this.$i18n.locale,
      }

      this.$modal.open(modalOptions)
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth > 1024) {
        this.isMobile = false

        return
      }

      this.isMobile = true
    })
  },
}

export default {
  props: {
    links: {
      type: [Array, Object],
    },

    position: {
      type: String,
      default: '',
      validator: prop => ['', 'fixed', 'sticky'].includes(prop),
    },
  },

  data() {
    return {
      previousScrollPositionY: 0,
      dataPosition: this.position,
      isMobile: null,
    }
  },

  computed: {
    sections() {
      const sections = []

      this.links.forEach(link => {
        const id = this.getDataAnchorId(link.href)
        if (process.client) {
          const element = document.getElementById(id)
          if (element)
            sections.push({
              id,
              position: element.offsetTop - 300,
            })
        }
      })

      if (!this.isMobile && sections[1]) sections[1].position = 2000

      return sections
    },
  },

  mounted() {
    this.$nextTick(() => {
      let { y: previousScrollPositionY } = this.scrollPosition()
      this.previousScrollPositionY = previousScrollPositionY

      window.addEventListener('scroll', this.scroll, { capture: true, passive: true })
      window.dispatchEvent(new Event('scroll'))

      window.addEventListener('resize', this.resize)
      window.dispatchEvent(new Event('resize'))
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll)
  },

  methods: {
    getDataAnchorId(href) {
      return href.substring(1)
    },

    resize() {
      this.isMobile = window.innerWidth < 1024
    },

    scroll(e) {
      this.sections.forEach((section, index, array) => {
        if (this.scrollPosition().y >= section.position) {
          let elements = document.querySelectorAll('.anchor-link')

          elements.forEach(element => {
            element.classList.remove('active')
            element.blur()
          })
          const anchor = document.querySelector(`[data-anchor-id="${section.id}"]`)

          anchor.classList.add('active')
          document.activeElement.blur()

          const navAnchor = document.querySelector('.nav-anchor')
          if (this.isScrollVertically()) {
            if (index === array.length - 1) navAnchor.scrollLeft = 1000
            else navAnchor.scrollLeft = 0
          }
        }
      })
      let { y: previousScrollPositionY } = this.scrollPosition()
      this.previousScrollPositionY = previousScrollPositionY
    },

    scrollPosition() {
      let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollMinY = 0
      const scrollMaxY =
        window.scrollMaxY || document.getElementById('__layout').scrollHeight - document.documentElement.clientHeight

      if (scrollPositionY < scrollMinY) scrollPositionY = scrollMinY
      if (scrollPositionY > scrollMaxY) scrollPositionY = scrollMaxY
      if (scrollPositionY > scrollMaxY - 800) this.dataPosition = ''
      else this.dataPosition = this.position

      return {
        y: scrollPositionY,
      }
    },

    isScrollVertically() {
      const { y: currentScrollPositionY } = this.scrollPosition()
      const isScrollVertically =
        this.previousScrollPositionY < currentScrollPositionY || this.previousScrollPositionY > currentScrollPositionY

      return isScrollVertically
    },
  },
}

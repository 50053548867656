export default {
  props: {
    hasLogo: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    hotLoading: () => import('~/components/Organisms/Loading/Loading.vue'),
  },

  data() {
    return {
      url: process.env.BASE_URL,
      loading: true,
    }
  },

  methods: {
    onIframeLoad() {
      this.loading = false
    },
  },

  computed: {
    isHomepage() {
      return Boolean(this.$route.name && this.$route.name.includes('index'))
    },

    hostname() {
      const urlObject = new URL(this.url)

      return urlObject.hostname
    },

    service() {
      if (this.url === 'https://hotmart.com') {
        return 'https://app.hotmart.com/'
      }

      return 'https://app.buildstaging.com/'
    },

    path() {
      return this.$route.path
    },

    logo() {
      return this.hasLogo ? '' : '&nologo'
    },

    query() {
      return Object.keys(this.$route.query)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.$route.query[key])}`)
        .join('&')
    },
  },
}

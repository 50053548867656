export default {
  props: {
    name: {
      type: String,
    },
    course: {
      type: String,
    },
    image: {
      type: Object,
    },
    brandImage: {
      type: Object,
    },
  },
}

export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    author: {
      type: String,
    },
    role: {
      type: String,
    },
    text: {
      type: String,
    },
    image: {
      type: Object,
    },
  },
}

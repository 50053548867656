import { getDeviceSize } from '~/utils/device'
import debounce from 'lodash.debounce'

export default {
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    button: {
      type: Object,
    },
    hotmartIcon: {
      type: Object,
    },
    image: {
      type: Object,
    },
  },

  data() {
    return {
      isDesktop: null,
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resize)

      window.dispatchEvent(new Event('resize'))
      this.checkDeviceSize()
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    checkDeviceSize() {
      const device = getDeviceSize()
      const regex = /^(?=.*desktop-[xml])(?!.*desktop-[^xml]).*$/

      if (regex.test(device)) {
        this.isDesktop = true
        this.hasDeviceSize = true
      } else {
        this.isDesktop = false
        this.hasDeviceSize = true
      }
    },

    resize(e) {
      this.resizeDebounce(e, this)
    },

    scrollToTop() {
      if (this.isDesktop) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        const bannerHeight = 115
        const top =
          document.getElementById('iframe-sso')?.offsetTop -
          document.getElementById('header')?.offsetHeight -
          bannerHeight

        window.scrollTo({ top, behavior: 'smooth' })
      }
    },

    resizeDebounce: debounce((e, self) => {
      const deviceSize = getDeviceSize()

      const regex = /^(?=.*desktop-[xml])(?!.*desktop-[^xml]).*$/

      self.isDesktop = regex.test(deviceSize)
    }, 100),
  },
}
